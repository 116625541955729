import * as d3 from 'd3'
import { IntlShape } from 'gatsby-plugin-intl'
import { Tooltip } from './Tooltip';
import { diffGradient, nowMaskGradient } from './utils';

export function render(intl: IntlShape, data: any, target: HTMLElement) {
  // setup general chart parameters
  const width = 600
  const height = 180
  const margin = { top: 20, right: 94, bottom: 30, left: 45 }
  const axisMargin = 10

  // create x and y scales
  const x = d3
      .scaleTime()
      .domain([d3.min(data, d =>d.timestamp), d3.max(data, d =>d.timestamp)])
      .range([margin.left, width - margin.right])

  const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => Math.max(d.muun, d.bitcoind, d.optimum))])
      .range([height - margin.bottom, margin.top])

  const formatDate = (d: Date) =>
    intl.formatDate(d, { weekday: "short" }).replace(".", "")

  // create x and y axis
  const xAxis = g =>
      g.attr("transform", `translate(0,${height - margin.bottom + axisMargin})`)
        .call(d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
          .tickFormat(formatDate)
        )

  const yAxis = g =>
      g.attr("transform", `translate(${margin.left - axisMargin},0)`)
        .call(d3.axisLeft(y).ticks(height / 80))
        .call(g => g.select(".domain").remove())


  // draws a single chart line
  function line(svg, prop) {
      const line = d3
          .line()
          .x(d => x(d.timestamp))
          .y(d => y(d[prop]));

      // add line
      return svg
          .append("path")
          .attr("fill", "none")
          .attr("stroke-width", 2)
          .attr("stroke-miterlimit", 1)
          .attr("d", line(data));
  }

  // draws a single chart area below line
  function area(svg, prop) {
      const area = d3
        .area()
        .x(d => x(d.timestamp))
        .y0(height - margin.bottom)
        .y1(d => y(d[prop]));

      return svg
        .append("path")
        .data([data])
        .attr("d", area(data));
  }

  const tooltip = new Tooltip(x, y);

  const svg = d3.create("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("defs")
    .call(diffGradient)
    .call(nowMaskGradient)

  // add plot areas
  area(svg, "bitcoind").attr("fill", "url(#diffG)");

  area(svg, "muun").attr("fill", "white");

  // add plot lines
  line(svg, "bitcoind").attr("stroke", "#ff9438");

  line(svg, "optimum")
      .attr("stroke", "#C5C8D1")
      .attr("stroke-width", 1.5)
      .attr("stroke-dasharray", "12 3");

  line(svg, "muun").attr("stroke", "#3970DB");

  // add graphical axis
  svg
      .append("g")
      .call(xAxis)
      .attr("font-size", 11)
      .attr("color", "#EEEEEE")
      .call(g => g.selectAll(".tick text")
        .attr("font-family", "Rubik, Arial, Helvetica, sans-serif")
        .attr("fill", "#9BA0A7"))
      .append("g")
        .attr("class", "tick")
        .attr("transform", `translate(${width - margin.right}, 0)`)
        .call(g => g
          .append("rect")
          .attr("fill", "url(#nowMaskG)")
          .attr("x", -50)
          .attr("y", 6)
          .attr("width", 70)
          .attr("height", 30))
        .call(g => g
          .append("line")
            .attr("stroke", "#182449")
            .attr("y2", "6"))
        .call(g => g
          .append("text")
            .attr("transform", "translate(0, 17)")
            .attr("fill", "#182449")
            .text(intl.formatMessage({ id: "now", defaultMessage: "Now" })))

  svg
      .append("g")
      .call(yAxis)
      .attr("font-size", 11)
      .attr("color", "#EEEEEE")
      .call(g => g.selectAll(".tick text")
        .attr("font-family", "Rubik, Arial, Helvetica, sans-serif")
        .attr("fill", "#9BA0A7"))

  // returns the height of the last element in the given dataset
  function getLastHeight(data, prop) {
      if (data.length === 0) {
        return 0
      }
      const lastValue = data[data.length - 1];
      return y(lastValue[prop]) + 5
  }

  // draw labels to the right of the chart
  const labels = [
    { posY: getLastHeight(data, "bitcoind"), fill: "#ff9438", text: "Bitcoin Core" },
    { posY: getLastHeight(data, "muun"), fill: "#256BED", text: "Muun" },
    { posY: getLastHeight(data, "optimum"), fill: "#b2b2b2", text: intl.formatMessage({ id: "optimal", defaultMessage: "Optimal" }) },
  ]

  // make sure label texts don't overlap by at least 15 pixels
  // first, sort the labels in the array from top to bottom
  labels.sort((a, b) => a.posY - b.posY)
  for (let i = 1; i < labels.length; i++) {
    // for each label (starting from the second one) make sure its position in the y axis
    // is at least the height of the previous label. This can happen if a previous label
    // has had its position adjusted and now sits below the current one.
    labels[i].posY = Math.max(labels[i-1].posY, labels[i].posY)

    // compute the distance between the heights of this label and the previous one
    // if the distance is less than 15px, add some padding to position the element at least
    // 15 pixels below the previous one (height increases from top to bottom)
    const diff = labels[i].posY - labels[i-1].posY
    if (diff < 15) {
      labels[i].posY = labels[i].posY + (15 - diff)
    }
  }

  for (let label of labels) {
    svg
      .append("text")
      .attr("font-family", "Rubik, Arial, Helvetica, sans-serif")
      .attr("font-weight", "500")
      .attr("font-size", 11)
      .attr("x", width - margin.right + 12)
      .attr("y", label.posY)
      .attr("fill", label.fill)
      .text(label.text);
  }

  // add tooltips
  const sel = svg
      .append("g")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", d => x(d.timestamp))
      .attr("height", height)
      .attr("width", () => width);

  sel.on("mouseover", function(event, d) {
      const e = sel.nodes();
      const i = e.indexOf(this);
      tooltip.show(d, i);
  }).on("mouseout", () => tooltip.hide());

  svg.append(() => tooltip.node);

  d3.select(target)
      .append(() => svg.node());
}
