import React, { useEffect } from 'react'
import * as d3 from 'd3'
import { FormattedMessage, FormattedRelativeTime, IntlShape, useIntl } from 'gatsby-plugin-intl'
import throttle from 'lodash.throttle'
import { render } from '../charts/fees'
import { render as renderMobile } from '../charts/fees-mobile'
import { useListener } from '../useListener'
import { FEE_ESTIMATOR_LANDING_CSV_URL } from '../api_urls'

function renderResponsive(intl: IntlShape, data: any, target: HTMLElement) {
  if (window.matchMedia("(max-width: 992px)").matches) {
    renderMobile(intl, data, target)
  } else {
    render(intl, data, target)
  }
}

const FeeChart = () => {
    const intl = useIntl()
    const [lastUpdated, setLastUpdated] = React.useState<number | null>(null)
    const [data, setData] = React.useState<any>(null)
    const ref = React.useRef(null)

    useEffect(() => {
      d3.csv(FEE_ESTIMATOR_LANDING_CSV_URL, d3.autoType).then((data) => {
        if (data == null) {
          console.error("Failed to retrieve data for fees chart")
          return
        }

        for (let item of data) {
          item.timestamp = new Date(item.end_timestamp * 1000)
        }

        const lastValue = data[data.length - 1]
        const lastUpdated = Math.round((lastValue.timestamp - new Date()) / 1000)
        setLastUpdated(lastUpdated)
        setData(data)

        renderResponsive(intl, data, ref.current)
      })
    }, [])

    if (typeof window !== 'undefined') {
      useListener(window, 'resize', throttle(() => {
        if (!data) {
          return
        }
        const target = ref.current
        d3.select(target).selectAll("*").remove()
        renderResponsive(intl, data, ref.current)
      }, 500, { leading: true }))
    }

    return (
      <div className="fee-chart-wrapper">
        <div style={{overflow: "auto"}}>
          <h4>
            <FormattedMessage id="fees_chart_title" defaultMessage="Next-block fees" />
            &nbsp;<small><FormattedMessage id="fee_chart_units" defaultMessage="(in sat/vB)" /></small>
          </h4>
          <LastUpdated className="d-none d-lg-block" timestamp={lastUpdated} />
        </div>
        <ul className="legend d-lg-none">
          <li style={{ color: '#FF9338' }}><strong>—</strong> Bitcoin Core</li>
          <li style={{ color: '#2573F7' }}><strong>—</strong> Muun</li>
          <li style={{ color: '#9E9E9E' }}><strong>—</strong> Optimal</li>
        </ul>
        <div className="fee-chart" ref={ref} />
        <LastUpdated className="d-lg-none" timestamp={lastUpdated} />
      </div>
    )
}

interface LastUpdatedProps {
  timestamp: number | null
  className?: string
}

const LastUpdated = ({ timestamp, className }: LastUpdatedProps) => {
  if (!timestamp) {
    return null
  }

  return (
    <div className={"last-updated " + className}>
      <FormattedMessage id="last_updated" defaultMessage="Last updated" /> <span className="label"><FormattedRelativeTime value={timestamp} numeric="auto" updateIntervalInSeconds={10} /></span>
    </div>
  )
}

export default FeeChart
