import { svg } from './svg'

export class Tooltip {
    private xAxis: (number) => number
    private yAxis: (number) => number
    private _tooltip: SVGRectElement
    private _value: SVGTextElement
    private _muunGroup: SVGGElement
    private _bitcoindGroup: SVGGElement
    node: SVGGElement

    constructor(xAxis, yAxis) {
      this.xAxis = xAxis
      this.yAxis = yAxis

      this._tooltip = svg`<rect x="9" y="-10" width="87" height="21" fill="#071220" rx="5" ry="5"></rect>`;
      this._value = svg`<text x="16" y="4" font-weight="500" font-family="Rubik, Arial, Helvetica, sans-serif" font-size="9"></text>`;

      this._muunGroup = svg`<g font-family="sans-serif" font-size="12" text-anchor="left">
        ${this._tooltip}
        ${this._value}
        ${this.createCircle("#256BED")}
      </g>`;
      this._bitcoindGroup = svg`<g>${this.createCircle("#FF9438")}</g>`;

      this.node = svg`<g pointer-events="none" display="none" font-family="sans-serif" font-size="12" text-anchor="left">${this._bitcoindGroup}${this._muunGroup}</g>`
    }

    show(d, i) {
      const { xAxis, yAxis } = this

      this.node.removeAttribute("display");
      this._muunGroup.setAttribute(
        "transform",
        `translate(${xAxis(d.timestamp)},${yAxis(d.muun)})`
      );
      this._bitcoindGroup.setAttribute(
        "transform",
        `translate(${xAxis(d.timestamp)},${yAxis(d.bitcoind)})`
      );
  
      const savings = Math.round((1 - d.muun / d.bitcoind) * 100);
      this._value.textContent = this.getLabel(savings);
      this._value.setAttribute("fill", savings >= 0 ? "#a0f2a9" : "white");
  
      const width = this._value.getBBox().width + 14;
      this._tooltip.setAttribute("width", `${width}`);

      // mobile only
      if (window.matchMedia("(max-width: 992px)").matches) {
        if (i > 300) {
          this._tooltip.setAttribute("transform", `translate(-${width + 20}, 0)`)
          this._value.setAttribute("transform", `translate(-${width + 20}, 0)`)
        } else {
          this._tooltip.removeAttribute("transform")
          this._value.removeAttribute("transform")
        }
      }
    }

    hide() {
      this.node.setAttribute("display", "none");
    }
  
    getLabel(savings) {
      const label =
        savings >= 0 ? "Saved " + savings + "%" : "Overpaid " + -savings + "%";
      return label.toUpperCase();
    }
  
    createCircle(color) {
      return svg`<circle r="3" fill="white" stroke="${color}" stroke-width="2"></circle>`
    }
}
