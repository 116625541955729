let data: string | null

export function drawStars(target: HTMLElement) {
  if (!data) {
    const canvas = document.createElement("canvas")

    // Compute dimensions of the target element
    canvas.width = target.clientWidth
    canvas.height = target.clientHeight - 230
    draw(canvas)

    data = canvas.toDataURL()
  }

  target.style.backgroundPosition = "top center"
  target.style.backgroundRepeat = "no-repeat"
  target.style.backgroundImage = `url(${data})`
}

function draw(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext("2d")

  // Draw stars
  for (let i = 0; i < 15; i++) {
    const r = Math.round(Math.random() * 2) + 1
    const x = Math.round(Math.random() * canvas.width)
    const y = Math.round(Math.random() * canvas.height)

    let gradient = ctx.createRadialGradient(x, y, 0, x, y, r)
    gradient.addColorStop(0, "white")
    gradient.addColorStop(1, "transparent")

    ctx.fillStyle = gradient

    ctx.beginPath()
    ctx.arc(x, y, r, 0, 2 * Math.PI)
    ctx.fill()
  }

  // Draw shooting stars
  for (let i = 0; i < 3; i++) {
    const l = Math.round(Math.random() * 20) + 100
    const x1 = Math.round(Math.random() * canvas.width)
    const y1 = Math.round(Math.random() * canvas.height)
    const x2 = x1 + l + Math.random() * 30
    const y2 = y1 - l

    let gradient = ctx.createLinearGradient(x1, y1, x2, y2)
    gradient.addColorStop(0, "transparent")
    gradient.addColorStop(1, "rgba(37, 115, 247, 0.3)")

    ctx.strokeStyle = gradient

    ctx.beginPath()
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke()
  }
}
