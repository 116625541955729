export const diffGradient = defs => {
  const gradient = defs.append("linearGradient")
    .attr("id", "diffG")
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "0%")
    .attr("y2", "100%");

  gradient.append("stop")
    .attr('class', 'start')
    .attr("offset", "0%")
    .attr("stop-color", "#E3ECFF")
    .attr("stop-opacity", 1);

  gradient.append("stop")
    .attr('class', 'end')
    .attr("offset", "100%")
    .attr("stop-color", "white")
    .attr("stop-opacity", 1);
}

export const nowMaskGradient = defs => {
  const gradient = defs.append("linearGradient")
    .attr("id", "nowMaskG")
    .attr("x1", "0%")
    .attr("x2", "100%")
    .attr("y1", "0%")
    .attr("y2", "0%");

  gradient.append("stop")
    .attr('class', 'start')
    .attr("offset", "0%")
    .attr("stop-color", "white")
    .attr("stop-opacity", 0);

  gradient.append("stop")
    .attr('class', 'end')
    .attr("offset", "50%")
    .attr("stop-color", "white")
    .attr("stop-opacity", 1);
}
