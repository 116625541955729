import React, { useLayoutEffect } from "react"
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import FeeChart from "../components/FeeChart"
import CallToAction from "../components/CallToAction"
import Head from "../components/Head"
import Modal from "../components/Modal"
import { PlayStoreButton } from "../components/PlayStoreButton"
import { AppStoreButton } from "../components/AppStoreButton"
import { drawStars } from "../stars"
import astronautImage from "../assets/astronaut.png"
import padlocksImage from "../assets/padlocks.svg"
import recoveryCodeImage from "../assets/recovery-code.svg"
import phoneHackedImage from "../assets/phone-hacked.svg"
import phoneStolenImage from "../assets/phone-stolen.svg"
import { useListener } from "../useListener"
import { videos, images, notificationImages } from "../assets"
import { getLanguage } from "../language"
import Icon from "../components/Icon"

if (typeof window !== "undefined") {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}


const Home = () => {
  const intl = useIntl()
  const heroBg = React.useRef()

  if (typeof document !== "undefined") {
    useListener(document, 'resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    })
  }

  // redirect to spanish version if necessary
  if (typeof window !== "undefined") {
    if (window.location.pathname == "/" && getLanguage() == "es") {
      window.location.pathname = "/es"
    }
  }

  const [notifications, setNotifications] = React.useState<{ key: number, img: string }[]>([])
  const [showEmergencyKitModal, setShowEmergencyKitModal] = React.useState<boolean>(false)

  function addNotification(imgName: string) {
    const imgSrc = notificationImages[imgName][intl.locale]
    notifications.unshift({ key: Math.random(), img: imgSrc })
    const updated = notifications.slice(0, 3)
    setNotifications(updated)
  }

  const events = [
    { timestamp: 5, do: () => addNotification("tx1") },
    { timestamp: 11.7, do: () => addNotification("tx2") },
    { timestamp: 18, do: () => addNotification("tx3") },
    { timestamp: 24.7, do: () => addNotification("tx4") },
  ]

  const onMovieTimeUpdate = React.useMemo(() => {
    let idx = 0;
    let previousTime: number = 0

    return function onMovieTimeUpdate(e: React.SyntheticEvent<HTMLVideoElement>) {
      const time = e.currentTarget.currentTime

      if (time < previousTime) {
        previousTime = 0
        idx = 0
      }
      if (idx >= events.length) {
        return
      }

      const nextEvent = events[idx]

      if (previousTime <= nextEvent.timestamp && time >= nextEvent.timestamp) {
        nextEvent.do()
        idx++
      }

      previousTime = time
    }
  }, [])

  function onExpandEmergencyKitClick(e: React.SyntheticEvent<HTMLAnchorElement>) {
    setShowEmergencyKitModal(!showEmergencyKitModal)
  }

  useLayoutEffect(() => {
    drawStars(heroBg.current)

    const videos = document.getElementsByTagName("video")
    for (let video of videos) {
      (video as any).disablePictureInPicture = true;
      (video as any).disableRemotePlayback = true;
    }
  })

  const title = intl.formatMessage({
    id: "landing_title",
    defaultMessage: "Muun - Bitcoin and Lightning Network"
  })

  const description = intl.formatMessage({
    id: "site_description",
    defaultMessage: "Muun is a self-custodial wallet for Android and iOS. Make fast and cheap payments. Keep your money safe."
  })

  return (
    <Layout className="landing">
      <Head title={title} description={description} />
      <div className="hero-wrapper" ref={heroBg}>
        <Header />
        <section className="hero">
          <div className="container">
            <canvas ref={heroBg} width="800" height="600" style={{display: "none"}}></canvas>
            <h1>
              <FormattedMessage id="hero_title_line_1" defaultMessage="Simple and powerful." /><br/>
              <FormattedMessage id="hero_title_line_2" defaultMessage="Just like bitcoin." />
            </h1>
            <p><FormattedMessage id="hero_description" defaultMessage="Muun is a self-custodial wallet for bitcoin and lightning." /></p>
            <div className="store-links d-md-block">
              <div className="col-mobile">
                <AppStoreButton />
              </div>
              <div className="col-mobile">
                <PlayStoreButton />
              </div>
            </div>
            <img className="astronaut-img" src={astronautImage} width="97" height="193" alt=""/>
          </div>
        </section>
      </div>
      <section className="lightning-payments">
        <div className="container">
          <div className="col-md-10 offset-md-1">
            <h3><FormattedMessage id="lightning_payments_title" defaultMessage="Lightning fast payments" /></h3>
            <p className="mb-0">
              <FormattedMessage
                id="lightning_payments_description"
                defaultMessage="Send and receive <b>instant</b> payments using the Lightning Network. {br} Pay fees as low as <b>1 satoshi</b>."
                values={{b: s => <strong>{s}</strong>, br: <br className="d-none d-md-inline" />}}
              />
            </p>
          </div>
          <div className="video-wrap">
            <div className="video-center">
              <video autoPlay muted loop playsInline src={videos.lightningFastPayments[intl.locale]} width="607" height="517" />
            </div>
          </div>
        </div>
      </section>
      <section className="scan-and-pay">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5 offset-lg-1">
              <h3>
                <FormattedMessage
                  id="scan_and_pay_title"
                  defaultMessage="Scan and pay{br}<strike>on-chain</strike>{br}<strike>off-chain</strike>{br}with bitcoin"
                  values={{strike: s => <span className="strike">{s}</span>, br: <br/>}}
                />
              </h3>
              <p>
                <FormattedMessage
                  id="scan_and_pay_description"
                  defaultMessage="Make all payments from a <b>single balance</b>. One wallet, one way."
                  values={{b: s => <strong>{s}</strong>}}
                />
              </p>
            </div>
            <div className="col-md-6">
              <video onTimeUpdate={onMovieTimeUpdate} autoPlay muted loop playsInline src={videos.payWithBtc[intl.locale]} />
              <TransitionGroup className="push-notifications">
                {notifications.map((n) =>
                  <CSSTransition timeout={1000} classNames="notification" key={n.key}>
                    <div className="notification">
                      <img className="notification-img" src={n.img} width="236" height="52" />
                    </div>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </div>
          </div>
        </div>
      </section>
      <section className="fees" id="fees">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h3>
                <FormattedMessage id="fees_title" defaultMessage="Stop overpaying fees" />
              </h3>
            </div>
          </div>
          <div className="card">
            <FeeChart />
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <p>
                <FormattedMessage
                  id="fees_description"
                  defaultMessage="Muun's <b>mempool-based estimator</b> gets your transaction confirmed fast without overpaying. Read how it works <read-more>here</read-more>."
                  values={{
                    b: s => <strong>{s}</strong>,
                    "read-more": s => <a href={intl.formatMessage({ id: "fee_estimator_post_link", defaultMessage: "https://blog.muun.com/why-is-muuns-fee-estimator-more-efficient/" })}>{s}</a>
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="own-your-bitcoin" id="self-custody">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h3>
                <FormattedMessage
                  id="own_your_bitcoin_title"
                  defaultMessage="<highlight>Own</highlight> your bitcoin.{br} It was built for that"
                  values={{
                    highlight: s => <span className="highlight">{s}</span>,
                    br: <br/>
                  }}
                />
              </h3>
              <p>
                <FormattedMessage
                  id="own_your_bitcoin_description"
                  defaultMessage="Export an Emergency Kit with your <b>private keys</b> and <b>output descriptors</b>.{br} Designed to fit complex scripts such as multisig, lightning and taproot."
                  values={{
                    b: s => <strong>{s}</strong>,
                    br: <br className="d-none d-md-inline" />
                  }}
                />
                &nbsp;
                <br className="d-none d-md-inline" />
                <FormattedMessage
                  id="own_your_bitcoin_description_cta"
                  defaultMessage="Read why Muun doesn't use mnemonics <read-more>here</read-more>."
                  values={{
                    "read-more": s => <a href={intl.formatMessage({ id: "mmenomic_post_link", defaultMessage: "https://blog.muun.com/why-not-just-a-mnemonic/" })}>{s}</a>
                  }}
                />
              </p>
              <div className="pt-3">
                <a className="read-more-link" onClick={onExpandEmergencyKitClick}>
                    <FormattedMessage
                      id="explore_the_emergency_kit"
                      defaultMessage="Explore the Emergency Kit"
                    />
                    <Icon icon="read-more" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="recovery-methods" id="recovery">
        <div className="container">
          <h3>
            <FormattedMessage
              id="recovery_title"
              defaultMessage="Choose the right recovery <highlight>for you</highlight>"
              values={{highlight: s => <span className="highlight">{s}</span>}}
            />
          </h3>
          <p>
            <FormattedMessage
              id="recovery_description"
              defaultMessage="Create a back-up to safely access your wallet if you change your phone."
            />
          </p>
          <div className="row">
            <div className="col-md">
              <div className="card first">
                <img className="card-image" src={recoveryCodeImage} alt=""/>
                <h4>
                  <FormattedMessage
                    id="recovery_code_title"
                    defaultMessage="Cold Recovery Code"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="recovery_code_description"
                    defaultMessage="Use a randomly-generated code written on paper."
                  />
                </p>
              </div>
            </div>
            <div className="col-md">
              <div className="card last">
                <img className="card-image" src={padlocksImage} alt=""/>
                <h4>
                  <FormattedMessage
                    id="mfa_title"
                    defaultMessage="Multi-factor Authentication"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="mfa_description"
                    defaultMessage="Use your email, password and recovery code."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="safer-than-ever" id="security">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h3>
                <FormattedMessage
                  id="safer_than_ever_title"
                  defaultMessage="Your bitcoin, safer than ever"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="safer_than_ever_description"
                  defaultMessage="Muun is a <b>2-of-2 multi-signature</b> wallet.{br} Your Emergency Kit has both keys, but your phone just one.{br}<read-more>Read how it works here.</read-more>"
                  values={{
                    b: s => <strong>{s}</strong>,
                    br: <br className="d-none d-md-inline" />,
                    "read-more": s => <a href={intl.formatMessage({ id: "safer_than_ever_post_link", defaultMessage: "https://blog.muun.com/muuns-multisig-model/" })}>{s}</a>
                  }}
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <div className="card first">
                <img className="card-image" src={phoneHackedImage} alt=""/>
                <h4>
                  <FormattedMessage
                    id="phone_hacked_title"
                    defaultMessage="Phone hacked?"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="phone_hacked_description"
                    defaultMessage="Your money is safe. Two keys are needed to spend it and only one is in your phone."
                  />
                </p>
              </div>
            </div>
            <div className="col-md">
              <span className="card-label"><FormattedMessage id="coming_soon" defaultMessage="Coming soon" /></span>
              <div className="card last">
                <img className="card-image" src={phoneStolenImage} alt=""/>
                <h4>
                  <FormattedMessage
                    id="phone_stolen_title"
                    defaultMessage="Phone stolen?"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="phone_stolen_description"
                    defaultMessage="Lock your wallet until you get a new phone and recover your funds."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CallToAction />
      <Footer />
      <Modal show={showEmergencyKitModal} onClose={() => setShowEmergencyKitModal(false)}>
        <img src={images.emergencyKit[intl.locale]} alt=""/>
      </Modal>
    </Layout>
  )
}

export default Home
