import payWithBtcVideoEn from "./assets/pay_with_btc.mp4"
import payWithBtcVideoEs from "./assets/pay_with_btc_spanish.mp4"
import lightningFastPaymentsVideoEn from "./assets/lightning_fast_payments.mp4"
import lightningFastPaymentsVideoEs from "./assets/lightning_fast_payments_spanish.mp4"
import emergencyKitIllustrationImageEn from "./assets/EN_illustration_emergency-kit.svg"
import emergencyKitIllustrationImageEs from "./assets/ES_illustration_emergency-kit.svg"
import emergencyKitImageEn from "./assets/EN_Emergency-Kit.svg"
import emergencyKitImageEs from "./assets/ES_Emergency-Kit.svg"
import tx1En from "./assets/TX1.svg"
import tx2En from "./assets/TX2.svg"
import tx3En from "./assets/TX3.svg"
import tx4En from "./assets/TX4.svg"
import tx1Es from "./assets/TX1-ES.svg"
import tx2Es from "./assets/TX2-ES.svg"
import tx3Es from "./assets/TX3-ES.svg"
import tx4Es from "./assets/TX4-ES.svg"

export const videos = {
  payWithBtc: {
    en: payWithBtcVideoEn,
    es: payWithBtcVideoEs,
  },
  lightningFastPayments: {
    en: lightningFastPaymentsVideoEn,
    es: lightningFastPaymentsVideoEs,
  },
}

export const images = {
  emergencyKit: {
    en: emergencyKitImageEn,
    es: emergencyKitImageEs,
  }
}

export const notificationImages = {
  tx1: { en: tx1En, es: tx1Es },
  tx2: { en: tx2En, es: tx2Es },
  tx3: { en: tx3En, es: tx3Es },
  tx4: { en: tx4En, es: tx4Es },
}
